export default {
	cinema: {
		address: 'ул. Советская, 147',
		place: null,
		phoneNumbers: ['8 (47352) 2-11-72'],
		facebook: null,
		instagram: null,
		vk: 'https://vk.com/cinematalovay',
		twitter: null,
		odnoklassniki: 'https://ok.ru/group/54018108883080',
		youtube: null,
		app: {
			appstore: null,
			googleplay: null,
		},
		payment: {
			active: true,
			card: ['mir', 'visa', 'mastercard', 'maestro', 'sbp', 'sberpay'],
		},
		maps: 'https://yandex.ru/maps/-/CBR9Q6ETLD',
		selectButton: {
			options: [],
			selectedOptionValue: null,
		},
		soonPageIsEnabled: false,
		saleRules: false,
		details: false,
		isWidgetDisabled: false,
	},
	feedback: true,
};
