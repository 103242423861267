import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '36, 40, 56',
		'primary-dark': '21, 23, 30',
		'accent': '145, 159, 227',
		'accent-plus': '0, 0, 0',
	},
});
